import axios from 'axios'
import iscroll from 'iscroll'
import jsCookie from 'js-cookie'
import lodash from 'lodash'
import moment from 'moment'
import urijs from 'urijs'
import vue from 'vue'
import vueRouter from 'vue-router'
import vuex from 'vuex'

const GlobalNamespace = '__axe__'
const axe = {
  axios,
  iscroll,
  ['js-cookie']: jsCookie,
  lodash,
  moment,
  urijs,
  vue,
  ['vue-router']: vueRouter,
  vuex
}
window[GlobalNamespace] = axe
